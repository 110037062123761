import React, { Component } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import { withRouter } from "../../components/hoc/withRouter";
import { appRoutes } from "./../../helpers/constants";

import TermsConditionsInternal from "../GenericPages/TermsConditionsInternal";
import UnderConstruction from "../GenericPages/UnderConstruction";
import Sidebar from "../../components/common/adminLayout/Sidebar";
import UnderMaintenance from "../GenericPages/UnderMaintenance";
import Header from "../../components/common/adminLayout/Header";
//import Footer from "../../components/common/adminLayout/Footer";
import EnrichContactActivities from "../Enrich/ContactActivities";
import EnrichCompanyEmployees from "../Enrich/CompanyEmployees";
import PaymentSuccess from "../GenericPages/PaymentSuccess";
import PaymentError from "../GenericPages/PaymentError";
import ChangePassword from "../ChangePassword";
import UserManagement from "../UserManagement";
import EnrichContact from "../Enrich/Contact";
import EnrichCompany from "../Enrich/Company";
import LogsAnalytics from "../LogsAnalytics";
import AddCredits from "../AddCredits";
import APIKeys from "../API/APIKeys";
import Dashboard from "../Dashboard";
import Settings from "../Settings";
import Searches from "../Searches";
import Invoices from "../Invoices";
import MyTeam from "../MyTeam";

import { fetchApplication } from "../../actions/application";

const components = {
  termsConditionsInternal: TermsConditionsInternal,
  enrichContactActivities: EnrichContactActivities,
  enrichCompanyEmployees: EnrichCompanyEmployees,
  underConstruction: UnderConstruction,
  underMaintenance: UnderMaintenance,
  paymentSuccess: PaymentSuccess,
  changePassword: ChangePassword,
  userManagement: UserManagement,
  logsAnalytics: LogsAnalytics,
  enrichContact: EnrichContact,
  enrichCompany: EnrichCompany,
  paymentError: PaymentError,
  addCredits: AddCredits,
  dashboard: Dashboard,
  settings: Settings,
  searches: Searches,
  invoices: Invoices,
  APIKeys: APIKeys,
  myTeam: MyTeam,
}

class Application extends Component {
  componentDidMount() {
    const { fetchApplication } = this.props;

    if (typeof fetchApplication === "function") { fetchApplication(); }
  }

  render() {
    return (
      <div className="d-flex bg-white-f0">
        <Sidebar />

        <div className="wrapper flex-grow-1 py-4 px-5">
          <Header />
          <div className="wrapper-content">
            <Routes>
              {
                appRoutes.map(({ component, ...rest }, key) => {
                  const RouteComponent = (components[component] || "");

                  if (typeof RouteComponent !== "undefined" && RouteComponent !== "") {
                    return <Route key={key} {...rest} element={<RouteComponent />} />
                  } else {
                    return null;
                  }
                })
              }
              <Route path="*" element={<Navigate from="*" to="/" replace />} />
            </Routes>
          </div>
        </div>
        {/*<Footer />*/}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchApplication: () => dispatch(fetchApplication())
});

export default withRouter(connect(null, mapDispatchToProps)(injectIntl(Application)));
